// const backendDomain = "http://192.168.1.132/education_consultancy";
const backendDomain = "https://sample.oakya.tech/api";

const SummaryApi = {
  signUP: {
    url: `${backendDomain}/students/create.php`,
    method: "post",
  },
  otpVerification: {
    url: `${backendDomain}/student_login/update.php`,
    method: "post",
  },
  emailVerification: {
    url: `${backendDomain}/student_login/mail.php`,
    method: "post",
  },
  getStudentDetails: {
    url: `${backendDomain}/students/retrieve.php`,
    method: "post",
  },
  createCountry: {
    url: `${backendDomain}/country/create.php`,
    method: "post",
  },
  updateCountry: {
    url: `${backendDomain}/country/update.php`,
    method: "post",
  },
  getAllCountries: {
    url: `${backendDomain}/country/admincountry.php`,
    method: "get",
  },
  createUniversity: {
    url: `${backendDomain}/university/create.php`,
    method: "post",
  },
  updateUniversity: {
    url: `${backendDomain}/university/update.php`,
    method: "post",
  },
  getAllUniversities: {
    url: `${backendDomain}/university/adminuniversity.php`,
    method: "get",
  },
  getActiveUniversities:{
    url: `${backendDomain}/university/retrieve.php`,
    method: "get",
  },
  createCourse: {
    url: `${backendDomain}/course/create.php`,
    method: "post",
  },
  updateCourse: {
    url: `${backendDomain}/course/update.php`,
    method: "post",
  },
  getAllCourses: {
    url: `${backendDomain}/course/admincourse.php`,
    method: "get",
  },
  getCountryById: {
    url: `${backendDomain}/country/retrieve.php`,
    method: "post",
  },
  universityImg: {
    url: `${backendDomain}/university/`,
  },
  courseImg: {
    url: `${backendDomain}/course/`,
  },
  courseImg2: {
    url: `${backendDomain}/course/`,
  },
  getActiveCourses: {
    url: `${backendDomain}/course/retrieve.php`,
    method: "get",
  },
  courseActiveImg: {
    url: `${backendDomain}/course/`,
  },
  updateUniversitiesCourses: {
    url: `${backendDomain}/university_course/update.php`,
    method: "post",
  },
  getUniversitiesCourses: {
    url: `${backendDomain}/course/courseuniversity.php`,
    method: "post",
  },
  getmode1: {
    url: `${backendDomain}/mode/retrieve.php`,
    method: "post",
  },
  getmode2: {
    url: `${backendDomain}/mode/retrieve.php`,
    method: "put",
  },
  createmode: {
    url: `${backendDomain}/mode/create.php`,
    method: "post",
  },
  getCategory: {
    url: `${backendDomain}/category/retrieve.php`,
    method: "get",
  },
  getAdminStudents: {
    url: `${backendDomain}/students/retrieve.php`,
    method: "get",
  },
  studentImage: {
    url: `${backendDomain}/students/`,
  },
  dashboardCounts: {
    url: `${backendDomain}/dashboard/count.php`,
    method: "get",
  },
  studentImg: {
    url: `${backendDomain}/students/`,
  },
  updateStudent: {
    url: `${backendDomain}/students/update.php`,
    method: "post",
  },
  getAdminCategory: {
    url: `${backendDomain}/category/admincategory.php`,
    method: "get",
  },
  getActiveUniversities: {
    url: `${backendDomain}/university/retrieve.php`,
    method: "get",
  },
  createCategory: {
    url: `${backendDomain}/category/create.php`,
    method: "post",
  },
  updateCategory: {
    url: `${backendDomain}/category/update.php`,
    method: "post",
  },
  getCategoryByUniversity: {
    url: `${backendDomain}/category/retrieve.php`,
    method: "put",
  },
  createStudentEnroll: {
    url: `${backendDomain}/enrollment/create.php`,
    method: "post",
  },
  studentmailpopup: {
    url: `${backendDomain}/students/mailsend.php`,
    method: "post",
  },
  getCategoryByUniversity: {
    url: `${backendDomain}/category/retrieve.php`,
    method: "put",
  },
  getStudentById: {
    url: `${backendDomain}/enrollment/retrieve.php`,
    method: "put",
  },
};

export default SummaryApi;
